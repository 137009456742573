/**
 * @author sudong.duan
 * Date: 21-12-16
 * desc: axios请求方法
 */

import axios from 'axios'
import Vue from 'vue';
import {
	message
} from "ant-design-vue";
import {
	isEmpty
} from './common'


let baseUrl = ''

const responseCode = {
	/**
	 * 请求成功
	 */
	success: 200,
	/**
	 * 成功code2
	 */
	success2: 0,
	/**
	 * token失效
	 */
	tokenFailure: 201
}


//请求前拦截
axios.interceptors.request.use(
	(config) => {
		return config
	},
	(error) => {
		return error
	}
)

//请求返回拦截
axios.interceptors.response.use(
	(response) => {
		try {
			if (response) {
				const result = response
				return result
			}
		} catch (err) {
			return {}
		}
	},
	(error) => {
		if (error.response) {
			return error.response
		} else {
			return error
		}
	}
)

export const requestApi = (params) => {
	return new Promise((resolve, reject) => {
		params.method = params.method || 'POST'
		new Promise((res, rej) => {
			if (params.method === 'POST') {
				axios.post(params.url, params.data, {
					headers: Object.assign({
						"Content-Type": "application/json"
					}, params.headers || {}),
					baseUrl: params.baseUrl
				}).then((responseData) => {
					res(responseData)
				}).catch((errData) => {
					rej(errData)
				})
			} else if (params.method === 'GET') {
				axios.get(params.url, {
					params: params.data,
					responseType: params.responseType,
					headers: params.headers,
					baseUrl: ''
				}).then((responseData) => {
					res(responseData)
				}).catch((errData) => {
					rej(errData)
				})
			}
		}).then((res) => {
			if (res.status !== 200) {
				message.error('网络请求失败，请稍后再试')
				reject(res.data)
				return
			}
			if (isEmpty(res) || isEmpty(res.data)) {
				reject(res.data)
				return
			}
			if (params.notCheck) {
				resolve(res.data)
				return
			}

			if (res.data.code === responseCode.success || res.data.code === responseCode.success2) {
				resolve(res.data)
			} else if (res.data.code === responseCode.tokenFailure) {
				message.error(res.data.message || res.data.msg)
				reject(res.data)
			} else {
				if (params.isDefault) {

				}
				if (!params.hideNote) {
					message.error(res.data.message || res.data.msg)
				}
				reject(res.data)
			}
		}).catch((err) => {
			reject(err)
		})
	})
}