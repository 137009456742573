import Vue from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store'
import MetaInfo from 'vue-meta-info'
import 'ant-design-vue/dist/antd.css';
import { flexible } from "@/utils/flexible";
import '@/assets/css/base.less';
import FooterNav from '@/components/footerNav';
import FormNav from '@/components/formNav';
import SliderNav from '@/components/sliderNav';
import '@/fontFamily/font.css';
flexible(window, document);
Vue.use(MetaInfo)

Vue.config.productionTip = false;

Vue.component('FooterNav', FooterNav);
Vue.component('FormNav', FormNav);
Vue.component('SliderNav', SliderNav);

// 定义全局变量
Vue.prototype.$webType = 'dpp'; // cbia or dpp
new Vue({
  router,
  store,
  render: h => h(App),
  mounted () {
    document.dispatchEvent(new Event('render-event'))
  }
}).$mount('#app')
