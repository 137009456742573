<template>
    <div class="group_10 flex-row" id="anrchor">
        <div class="group_11 flex-col"></div>
        <span class="text_13">申请试用</span>
        <img class="image_19" referrerpolicy="no-referrer"
            :src="cardBackgroundImg" />
        <div class="group_12 flex-col">
            <span class="text_14">请留下您的联系方式与申请信息，我们将安排联合试点验证专员与您联系</span>
            <!-- form表单 -->
            <Form ref="ruleForm" :model="formState" :rules="formRules" label-width="1.3rem"
                class="form_wrapper flex-col">
                <FormItem label="公司名称" prop="company">
                    <Input v-model="formState.company" placeholder="请输入公司名称" />
                </FormItem>
                <FormItem label="联系人" prop="name">
                    <Input v-model="formState.name" placeholder="请输入姓名" />
                </FormItem>
                <FormItem label="联系电话" prop="phoneNumber">
                    <Input v-model="formState.phoneNumber" placeholder="请输入联系电话" />
                </FormItem>
                <FormItem label="邮件地址" prop="mail">
                    <Input v-model="formState.mail" placeholder="请输入邮件地址" />
                </FormItem>
                <FormItem label="部门或职位" prop="position">
                    <RadioGroup v-model="formState.position">
                        <Radio label="质量"></Radio>
                        <Radio label="IT"></Radio>
                        <Radio label="采购"></Radio>
                        <Radio label="其他"></Radio>
                    </RadioGroup>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="submitForm('ruleForm')">提交</Button>
                </FormItem>
            </Form>
            <!-- <div class="text-wrapper_9 flex-col">
                <span class="text_32">提交</span>
            </div> -->
        </div>
    </div>
</template>
<script>
import { Form, FormItem, Input, RadioGroup, Radio, Button, Message } from "element-ui";
import { requestApi } from '@/utils/axios';
import cardBackgroundImg from '@/assets/images/formNav/card_background.png'
export default {
    components: {
        Form,
        FormItem,
        Input,
        RadioGroup,
        Radio,
        Button
    },
    data() {
        return {
            cardBackgroundImg,
            formState: {
                company: '',
                name: '',
                phoneNumber: '',
                mail: '',
                position: ''
            },
            formRules: {
                company: [{ 
                        required: true, 
                        message: '请输入公司名称', 
                        trigger: 'blur' 
                    }],
                name: [{ 
                    required: true, 
                    message: '请输入联系人', 
                    trigger: 'blur'
                }],
                phoneNumber: [{ 
                    required: true, 
                    trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if(value == '') {
                            return callback(new Error('请输入联系电话'));
                        }
                        if (!/^1[3456789]\d{9}$/.test(value)) {
                            return callback(new Error('请输入正确的手机号'));
                        } else {
                            callback();
                        }
                    }
                }],
                mail: [{ 
                    required: true, 
                    trigger: 'blur',
                    validator: (rule, value, callback) => {
                        if(value == '') {
                            return callback(new Error('请输入邮件地址'));
                        }
                        if (!/^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/.test(value)) {
                            return callback(new Error('请输入正确的邮箱'));
                        } else {
                            callback();
                        }
                    }
                }],
                position: [{ 
                    required: true, 
                    message: '请选择部门', 
                    trigger: 'change' 
                }]
            }
        }
    },
    methods: {
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    requestApi({
                        url: '/api/appointment/add',
                        method: 'POST',
                        data: this.formState
                    }).then(res => {
                        if (res.code == 0) {
                            Message({
                                message: '已提交！稍后会有试点验证员与您联系。',
                                type: 'success'
                            });
                        this.$refs[formName].resetFields();
                        }
                    })
                } else {
                    Message({
                        showClose: true,
                        message: '信息提交失败，请重新尝试!',
                        type: 'error'
                    });
                }
            })
        }
    }
}
</script>
<style lang="less" scoped>
@import url('./index.less');
</style>
