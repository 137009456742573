<template>
    <div class="group_13 flex-col justify-end">
        <div class="group_14 flex-row">
            <span class="text_33">中国数字护照网</span>
            <span class="text_34">微信公众号</span>
            <span class="text_35">联系我们</span>
            <div class="section_12 flex-col"></div>
        </div>
        <div class="group_15 flex-row">
            <img class="image_20" referrerpolicy="no-referrer" :src="IconLogo" />
            <div class="image-wrapper_3 flex-col">
                <img class="label_7" referrerpolicy="no-referrer" :src="commpanyIcon" />
            </div>
            <div class="group_16 flex-col justify-between">
                <div class="block_5 flex-row justify-between">
                    <img class="label_8" referrerpolicy="no-referrer" :src="addressIcon" />
                    <span class="text_36">江苏省南京市江宁区秣周东路12号7号楼7楼</span>
                </div>
                <div class="image-text_11 flex-row justify-between">
                    <img class="label_9" referrerpolicy="no-referrer" :src="emailIcon" />
                    <span class="text-group_11">chdpp&#64;fc3de.com</span>
                </div>
            </div>
            <img class="thumbnail_4" referrerpolicy="no-referrer" :src="phoneIcon" />
            <span class="text_37">400-106-2918</span>
        </div>
        <div class="group_17 flex-col"></div>
        <div class="text-wrapper_10 flex-row">
            <span class="text_38">友情链接</span>
            <span class="text_39" @click="convertLoad('https://www.miit.gov.cn/')">中华人民共和国工业和信息化部</span>
            <span class="text_40" @click="convertLoad('http://www.caict.ac.cn/')">中国信息通信研究院</span>
            <span class="text_41" @click="convertLoad('https://www.idfactory.cn/')">中国工业互联网顶级节点</span>
        </div>
        <div class="text-wrapper_11 flex-row">
            <span class="text_42">Copyright © 2023 &nbsp; &nbsp; 南京复创智能制造技术有限责任公司 | <span class="copyright_active" @click="convertLoad('https://beian.miit.gov.cn/')">苏ICP备2022027126号-5</span></span>
        </div>
    </div>
</template>
<script>
import IconLogo from '@/assets/images/portal/logo.png';
import commpanyIcon from '@/assets/images/footerNav/commpany_icon.png';
import addressIcon from '@/assets/images/footerNav/address_icon.png';
import emailIcon from '@/assets/images/footerNav/email_icon.png';
import phoneIcon from '@/assets/images/footerNav/phone_icon.png'
export default {
    data() {
        return {
            IconLogo,
            commpanyIcon,
            addressIcon,
            emailIcon,
            phoneIcon
        }
    },
    methods: {
        convertLoad(url) {
            window.open(url);
        }
    }
}
</script>
<style lang="less" scoped>
@import url('./index.less');
</style>
