<template>
    <!-- 顶部导航栏 -->
    <div class="portal-nav">
        <div class="nav-logo">
            <img :src="logoImg" alt="logo" />
            <span>{{ $webType == 'cbia' ? `CBIA企业服务平台` : `中国产品数字护照网` }}</span>
        </div>
        <div class="nav-content">
            <div class="nav-list">
                <!-- 菜单项 -->
                <div :key="index" :class="['list-item', activeMenuStatus(index)]" v-for="(item, index) in menus"
                    @mouseenter="mouseenter(item, index)" @click="mouseclick(item, index, 'parent')">
                    {{ item.title }}
                    <img v-if="item.routeLevels" :class="[open && mouseIndex == index ? 'img-roate' : null]"
                        :src="clickIndex == index ? arrowActiveIcon : arrowIcon" />
                </div>
                <!-- 滑块展开 -->
                <div v-if="itemInfo.routeLevels" ref="sliderRef"
                    :class="['slider-style', 'slider-mode', sliderStatus(mouseIndex)]" @mouseleave="mouseleave">
                    <!-- 提示项 -->
                    <div class="slider-content">
                        <div class="slider-content_sider" v-if="itemInfo.routeVertials">
                            <ul class="content_sider-nav">
                                <li class="sider-nav_item" v-for="(item, index) in itemInfo.routeVertials" :key="index"
                                    @click="mouseclick(item, index, 'children')">
                                    {{ item.title }}
                                    <img :src="arrowRightIcon" />
                                </li>
                            </ul>
                        </div>
                        <div class="slider-content_main" v-if="itemInfo.routeContent">
                            <div class="content_main-title">{{ itemInfo.routeContent.title }}</div>
                            <div class="content_main-label">{{ itemInfo.routeContent.label }}</div>
                        </div>
                    </div>
                    <!-- 二级菜单项 -->
                    <ul class="slider-list" :key="index" v-for="(item, index) in itemInfo.routeLevels">
                            <li :class="['slider-item']" :key="subIndex" v-for="(subItem, subIndex) in item"
                                @click="mouseclick(subItem, subIndex, 'children')">
                                {{ subItem.title }}
                                <img :src="arrowRightIcon" />
                            </li>
                        </ul>
                    <!-- 二级菜单项内容 -->
                    <div class="slider-tooltip">
                        <div class="slider-tooltip_img">
                            <div class="tooltip_img-wrapper">
                                <p>{{ itemInfo.tips[0] }}</p>
                                <p>{{ itemInfo.tips[1] }}</p>
                            </div>
                            <img :src="itemInfo.img" />
                        </div>
                        <div class="slider-tooltip_nav" v-if="itemInfo.labels && $webType != 'cbia'">
                            <p>{{ itemInfo.labels[0] }}</p>
                            <p>{{ itemInfo.labels[1] }}</p>
                            <a href="#anrchor" @click="convertUrl('/home')">
                                {{ itemInfo.labels[2] }}
                                <img :src="chevronDownIcon" />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 操作项 -->
            <div class="nav-action">
                <template v-if="$webType == 'cbia'">
                    <div class="action-login">
                        <Button size="small" @click="openLink">
                            <span class="button-span">登录</span>
                            <img class="button-img" :src="userIcon" />
                        </Button>
                    </div>
                    <div class="action-download">
                        <Button type="primary" @click="downloadUrl('/download')" size="small">
                            <span class="button-span">文档下载</span>
                            <img class="button-img" :src="downloadIcon" />
                        </Button>
                    </div>
                </template>
                <template v-if="clickIndex == 0 && $webType == 'dpp'">
                    <div class="action-search">
                        <Input placeholder="请输入关键字" v-model="searchKeyWords" @keyup="findSearch" size="small"
                            width="150px">
                        <template slot="append">
                            <Button icon="el-icon-search" @click="findSearch" size="small">搜索</Button>
                        </template>
                        </Input>
                    </div>
                    <a href="#anrchor">
                        <Button type="primary" size="small">申请试用</Button>
                    </a>
                </template>
            </div>
        </div>
    </div>
</template>
<script>
import logoImg from '@/assets/images/portal/logo.png';
import frameOneImg from '@/assets/images/portal/frame_one.png';
import frameTwoImg from '@/assets/images/portal/frame_two.png';
import frameThreeImg from '@/assets/images/portal/frame_three.png';
import arrowIcon from '@/assets/images/portal/arrow.png';
import arrowActiveIcon from '@/assets/images/portal/arrow_active.png';
import chevronDownIcon from '@/assets/images/sliderNav/chevron-down.png';
import arrowRightIcon from '@/assets/images/sliderNav/arrow-right.png';
import userIcon from '@/assets/images/sliderNav/user-icon.png';
import downloadIcon from '@/assets/images/sliderNav/download-icon.png';
import { Button, Input } from 'element-ui';
export default {
    components: {
        Input,
        Button
    },
    data() {
        return {
            userIcon,
            downloadIcon,
            isFirstFindNode: false,
            searchKeyWords: '',
            clickIndex: 0,
            mouseIndex: 0,
            // isDownload: false,
            arrowIcon,
            arrowActiveIcon,
            chevronDownIcon,
            arrowRightIcon,
            open: false,
            logoImg,
            itemInfo: {},
            menus: [
                {
                    // 首页
                    title: '首页', path: '/home'
                },
                {
                    // 关于DPP
                    type: 'menu',
                    title: '关于DPP', path: '#',
                    routeVertials: [
                        { title: '关于DPP', path: '/aboutdpp' },
                        { title: '权威报告', path: '/technicalReport' },
                        { title: '技术标准', path: '/technicalStandard' }
                    ],
                    routeLevels: [
                        [
                            { title: '什么是产品数字护照?', path: '/aboutdpp#what' },
                            { title: '产品数字护照实施过程', path: '/aboutdpp#process' },
                            { title: '产品数字护照意义', path: '/aboutdpp#significance' }
                        ],
                        [
                            { title: '权威报告', path: '/technicalReport#report' },
                            { title: '中国电池数字护照体系白皮书', path: '/technicalReport#digitalize' }
                        ],
                        [
                            { title: '技术标准', path: '/technicalStandard#standard' }
                        ]
                    ],
                    indexs: [1],
                    img: frameOneImg,
                    tips: ['助力企业', '数字化转型'],
                    labels: ['申请试用', '申请试用, 与优秀企业家建联, 共建行业前瞻洞见.', '立即前往'],
                },
                {
                    // 电池数字护照
                    type: 'menu',
                    title: '产品数字护照', path: '#',
                    routeVertials: [
                        { title: '电池DPP', path: '/batteryIndustry' },
                        { title: '企业碳管理', path: '/carbonManage' }
                    ],
                    routeLevels: [
                        [
                            { title: '欧盟背景', path: '/batteryIndustry#unionBackground' },
                            { title: '市场现状', path: '/batteryIndustry#marketSituation' },
                            { title: '电池DPP的价值意义', path: '/batteryIndustry#valueSignificance' },
                            { title: '项目方案', path: '/batteryIndustry#projectPlan' }
                        ],
                        [
                            { title: '企业正面临着碳管理的挑战', path: '/carbonManage#challenge' },
                            { title: '应用场景', path: '/carbonManage#application' },
                            { title: '核心价值', path: '/carbonManage#coreValue' }
                        ]
                    ],
                    indexs: [2],
                    img: frameTwoImg,
                    tips: ['电池', '数字护照']
                },
                {
                    // 新闻动态
                    title: '新闻动态', path: '#',
                    routeLevels: [
                        [
                            { title: '政策解读', path: '/policyComment' },
                            { title: '行业资讯', path: '/industryInformation' },
                            { title: '工作动态', path: '/workNews' }
                        ]
                    ],
                    routeContent: {
                        title: '新闻动态',
                        label: '了解欧盟相关法规，行业资讯，技术，案例和生态, 带您见证产品数字护照带来的增长新动力。'
                    },
                    indexs: [3],
                    img: frameThreeImg,
                    tips: ['助力企业', '数字化转型']
                },
                { title: '关于我们', path: '/aboutUs' },
                { title: 'F.A.Q.', path: '/faq' }
            ],
        }
    },
    created() {
        this.$nextTick(() => {
            this.defaultActive(this.menus);
        })
    },
    computed: {
        // 滑块弹出验证状态
        sliderStatus() {
            return (index) => this.open ? `slider-open-mode${index}` : `slider-close-mode`;
        },
        // 菜单激活状态
        activeMenuStatus() {
            return (index) => {
                return [
                    this.clickIndex == index ? 'active' : null,
                    this.mouseIndex == index ? 'mouseActive' : null
                ]
            }
        },
    },
    watch: {
        '$route.path': {
            immediate: true,
            handler(value) {
                if (value.includes('/download')) {
                    this.clickIndex = -1;
                    this.mouseIndex = -1;
                    this.open = false;
                }
            }
        }
    },
    methods: {
        // 获取默认的激活菜单状态
        defaultActive(menus) {
            const recursionActive = (list, defaultIndex) => {
                list.forEach((item, index) => {
                    if (this.$route.path.includes(item.path)) {
                        this.clickIndex = defaultIndex !== undefined ? defaultIndex : index;
                        this.mouseIndex = defaultIndex !== undefined ? defaultIndex : index;
                        return;
                    }
                    item.children = item.routeVertials ? item.routeVertials : item.routeLevels;
                    if (item.children) recursionActive(item.children, index);
                })
            }
            recursionActive(menus);
        },
        // 鼠标移进
        mouseenter(item, index) {
            if (!item.children || this.mouseIndex != index) {
                this.mouseIndex = index;
                this.open = false;
            }
            this.itemInfo = item;
            this.open = true;
        },
        // 鼠标移出
        mouseleave(e) {
            if (e.relatedTarget.className == 'portal-nav') return;
            this.mouseIndex = this.clickIndex;
            this.open = false;
        },
        // 鼠标点击
        mouseclick(item, index, level) {
            this.searchKeyWords = '';
            if (item.children && level == 'parent') return;
            let addHashRoutePath = this.$route.path + this.$route.hash;
            if (item.path != addHashRoutePath) this.$router.push(item.path);
            this.clickIndex = level == 'children' ? this.mouseIndex : index;
            this.open = false;
        },
        // 路由跳转
        convertUrl(url) {
            this.open = false;
            if (this.$route.path != url) {
                this.$router.push(url);
                if (url == '/home') {
                    this.clickIndex = 0;
                    this.mouseIndex = 0;
                }
            }
        },
        openLink() {
            window.open('https://platform.cbpassport.com/', '_blank');
        },
        downloadUrl(url) {
            if (this.$route.path != url) {
                this.$router.push(url);
            }
        },
        findSearch() {
            const allNodes = document.querySelectorAll('.page');
            const nodesArray = Array.prototype.slice.call(allNodes);
            this.findSearchKeyWords(this.searchKeyWords, nodesArray);
        },
        findSearchKeyWords(keyWords, nodes) {
            this.isFirstFindNode = false;
            for (let i = 0; i < nodes.length; i++) {
                let item = nodes[i];
                if (item.children && item.children.length) {
                    this.findSearchKeyWords(keyWords, item.children);
                    if (this.isFirstFindNode) {
                        document.querySelectorAll('.set-keywords').forEach((item, index) => {
                            if (index != 0) item.parentNode.replaceChild(document.createTextNode(`${item.innerText}`), item);
                        });
                        break;
                    }
                } else {
                    if (item.classList.contains('set-keywords')) {
                        let cacheCurrentParentNode = item.parentNode;
                        item.parentNode.replaceChild(document.createTextNode(`${item.innerText}`), item);
                        this.handleNodeInfo(cacheCurrentParentNode, keyWords);
                    } else {
                        this.handleNodeInfo(item, keyWords);
                    }
                    document.querySelectorAll('.set-keywords').forEach((item, index) => {
                        if (index == 0) item.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    })
                }
            }
        },
        handleNodeInfo(item, keyWords) {
            if (item.innerText.indexOf(keyWords) != -1 && keyWords != '') {
                let newHtml = '';
                this.isFirstFindNode = true;
                item.innerText.split(keyWords).forEach((element, index) => {
                    if (index == 0) {
                        newHtml += element + '<span class="set-keywords" style="color: #0060dd;font-weight: bold;">' + keyWords + '</span>';
                    } else {
                        newHtml += element;
                    }
                });
                item.innerHTML = newHtml.trim();

            }
        }
    }
}
</script>
<style lang="less" scoped>
@import url('./index.less');
</style>